import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/Layout";
export const _frontmatter = {
  "title": "About"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`About Mindful Events and Registrations`}</h1>
    <p>{`Frank and Bob love events and retreats and other happenings organized by awesome
mindful and conscious communities.`}</p>
    <p>{`Often however, the registration for events, and how organizers prepare for it, is not so great.`}</p>
    <p>{`This can be done better! ... that's what they thought, and decided to build this system
to manage events, and to make registration as easy as possible.`}</p>
    <p>{`(pics of Frank and Bob)`}</p>
    <p>{`Frank and Bob both work for tech companies, so they thought "how hard can it be"...
and they put this first version of a registration system together. Will it work?
Let's find out :)`}</p>
    <p>{`You can contact them here:`}</p>
    <h1 id="contact">Contact</h1>
    <ul>
      <li parentName="ul">{`Frank: ...`}</li>
      <li parentName="ul">{`Bob: ...`}</li>
    </ul>
    <p>{`If you need immediate help, join this `}<a parentName="p" {...{
        "href": ""
      }}>{`group on Telegram`}</a>{`, and somebody will
answer your questions.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      